<template>
  <div>
    <Header :active="active" />
    <div class="service_box">
      <!-- banner -->
      <div class="banner">
        <div class="mengceng">
          <div class="contenter">
            <div class="title">品牌故事</div>
            <div class="tips">
              <img src="../assets/images/service/banner_tip.png" alt="" />
            </div>
            <div class="desc">
              取守正出奇之意，网络安全是个基于信任的生意，从业者心术不正走不远，但网络安全又是一个攻防双方智力的对决，不出奇
              招难以取胜。在与网络安全行业创业团队交流中，发现创业安全公司的创始人多是技术或产品背景，有过安全公司运营经验的
              成员很少，在公司发展节奏控制、产品规划、销售体系构建、内控、政府关系处理等方面完全没有意识或在走弯路，而网络安
              全产业其实是一个竞争很激烈，毛利率偏低的行业，走弯路所付出的代价可能是公司的缓慢发展甚至是死亡。
            </div>
          </div>
        </div>
        <div class="more_btn">
          <i class="icon el-icon-arrow-down"></i>
        </div>
      </div>
      <!-- 关于正奇 -->
      <div class="aboutZQ">
        <div class="contenter">
          <div class="title_box">
            <h2 class="title_name">关于正奇</h2>
            <span class="line"></span>
            <p class="slog">孵 化 网 络 安 全 创 新 创 业</p>
          </div>
          <div class="ZQdesc">
            为安全领域创业者开设的综合性实战课程与创业加速服务，打造健康的安全领域创业环境，帮助广大安全领域创业者解决创业路上的实际问题，科学创业，少走弯路，健康发展，同时为优秀的安全领域创业企业提供投融资等资源性服务。
          </div>
          <div class="ZQculture">
            <ul class="culture_list">
              <li class="culture_item">
                <div class="culture_box">
                  <div class="icon">
                    <img src="../assets/images/service/vision.png" alt="" />
                  </div>
                  <div class="name">愿景</div>
                  <div class="info">创新创业推动中国网络空间安全产业发展</div>
                </div>
              </li>
              <li class="culture_item">
                <div class="culture_box">
                  <div class="icon">
                    <img src="../assets/images/service/mission.png" alt="" />
                  </div>
                  <div class="name">使命</div>
                  <div class="info">助力安全领域的创业企业快速发展</div>
                </div>
              </li>
              <li class="culture_item">
                <div class="culture_box">
                  <div class="icon">
                    <img src="../assets/images/service/worth.png" alt="" />
                  </div>
                  <div class="name">价值观</div>
                  <div class="info">网安攻防正奇有位 产业科创守正出奇</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="more_btn">
          <i class="icon el-icon-arrow-down"></i>
        </div>
      </div>
      <!-- 优势与亮点 -->
      <div class="advantage">
        <div class="contenter">
          <div class="title_box">
            <h2 class="title_name">优势与亮点</h2>
            <span class="line"></span>
          </div>
          <div class="advantage_box">
            <div class="first">
              <div class="title">行业老鸟带队</div>
              <div class="info_desc">
                网络安全产业高管、领域专家亲临授课；
                多年经验，思想及实践、案例、方法论， 倾囊相授。
              </div>
            </div>
            <div class="center">
              <div class="second">
                <div class="title">实战认知课堂</div>
                <div class="info_desc">
                  体系化的安全领域创业课程有趣的夜话，
                  全员参与的模拟课堂&实战大课
                </div>
              </div>
              <div class="circle">
                <img src="../assets/images/service/saiboLogo.png" alt="" />
              </div>
              <div class="third">
                <div class="title">企业诊断及咨询</div>
                <div class="info_desc">
                  定期路演，360度诊断行业专家、安全同行共同参与依托数说安全，提供专业咨询
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="fourth">
                <div class="title">关键资源对接</div>
                <div class="info_desc">
                  投融资对接媒体等市场性资源对接政府关系对接其他关键性资源
                </div>
              </div>
              <div class="fifth">
                <div class="title">行业考察</div>
                <div class="info_desc">
                  安全强国以色列/美国的安全行业考察 （
                  <span style="color: #3ca9d8">视疫情情况而定</span>
                  ）以色列著名安全 展会（Israel HLS & Cyber ）
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="more_btn">
          <i class="icon el-icon-arrow-down"></i>
        </div>
      </div>
      <!-- 导师团 -->
      <div class="teacher_team">
        <div class="contenter">
          <div class="title_box">
            <h2 class="title_name">导师团</h2>
            <span class="line"></span>
          </div>
          <div class="teachers_box">
            <ul class="team_list clearfix">
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/WuYunkun.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">吴云坤</div>
                  <div class="company">奇安信集团</div>
                  <div class="position">总裁</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/RenZengqiang.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">任增强</div>
                  <div class="company">六方云</div>
                  <div class="position">创始人</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/JiXinhua.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">季昕华</div>
                  <div class="company">UCloud</div>
                  <div class="position">联合创始人兼CEO</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/CaiJingjing.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">蔡晶晶</div>
                  <div class="company">永信至诚</div>
                  <div class="position">创始人</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/WangWei.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">王伟</div>
                  <div class="company">指掌易科技</div>
                  <div class="position">创始人兼CEO</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/HuHongtao.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">胡洪涛</div>
                  <div class="company">苹果资本</div>
                  <div class="position">创始合伙人</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/ZhouHui.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">周辉</div>
                  <div class="company">融智咨询</div>
                  <div class="position">创始人</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/JInXiangyu.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">金湘宇</div>
                  <div class="company">赛博谛听</div>
                  <div class="position">创始人</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/LiShaopeng.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">李少鹏</div>
                  <div class="company">数世咨询</div>
                  <div class="position">创始人</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/ZhangYaojiang.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">张耀疆</div>
                  <div class="company">上海安言</div>
                  <div class="position">创始人</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/YeXiaohu.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">叶晓虎</div>
                  <div class="company">绿盟科技</div>
                  <div class="position">高级副总裁</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/KongJiyang.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">孔继阳</div>
                  <div class="company">天融信</div>
                  <div class="position">高级副总裁</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/HuangYiling.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">黄一玲</div>
                  <div class="company">深信服</div>
                  <div class="position">副总裁</div>
                </div>
              </li>
              <li class="team_item">
                <div class="tips">校长</div>
                <img
                  src="../assets/images/service/teachers/TanXiaosheng.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">谭晓生</div>
                  <div class="company">赛博英杰</div>
                  <div class="position">创始人</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/HanXiao.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">韩笑</div>
                  <div class="company">奇安信集团</div>
                  <div class="position">副总裁</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/NieJun.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">聂君</div>
                  <div class="company">网络安全专家</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/GuJian.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">顾坚</div>
                  <div class="company">前公安部<br />网络安全保卫局</div>
                  <div class="position">副局长</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/YuanMingkun.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">袁明坤</div>
                  <div class="company">安恒信息</div>
                  <div class="position">高级副总裁</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/XingBo.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">邢波</div>
                  <div class="company">东软集团</div>
                  <div class="position">副总裁</div>
                </div>
              </li>
              <li class="team_item">
                <img src="../assets/images/service/teachers/YaoYu.png" alt="" />
                <div class="teacher_info">
                  <div class="name">姚珏</div>
                  <div class="company">前360集团</div>
                  <div class="position">CFO</div>
                </div>
              </li>
              <li class="team_item">
                <img src="../assets/images/service/teachers/DuJun.png" alt="" />
                <div class="teacher_info">
                  <div class="name">杜军</div>
                  <div class="company">兴远BCC</div>
                  <div class="position">产品合伙人</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/LiuXiaozhong.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">刘晓忠</div>
                  <div class="company">中翰集团</div>
                  <div class="position">首席合伙人</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/XuJian.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">许坚</div>
                  <div class="company">DT资本合伙人</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/LiuHao.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">刘浩</div>
                  <div class="company">奇安信集团</div>
                  <div class="position">副总裁</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/WangZhao.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">王钊</div>
                  <div class="company">密码资本</div>
                  <div class="position">创始人</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/QuanXiaowen.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">权小文</div>
                  <div class="company">盛邦安全</div>
                  <div class="position">创始人&CEO</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/LongGuodong.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">龙国东</div>
                  <div class="company">威努特</div>
                  <div class="position">创始人&CEO</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/ChenHuaping.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">陈华平</div>
                  <div class="company">奇安信集团</div>
                  <div class="position">副总裁</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/DuanPingxia.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">段平霞</div>
                  <div class="company">安恒信息</div>
                  <div class="position">高级副总裁</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/LiuHongjin.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">刘红锦</div>
                  <div class="company">奇安信</div>
                  <div class="position">财务总监</div>
                </div>
              </li>
              <li class="team_item">
                <img src="../assets/images/service/teachers/SunYi.png" alt="" />
                <div class="teacher_info">
                  <div class="name">孙艺</div>
                  <div class="company">百森泰信</div>
                  <div class="position">董事长</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/HuangJing.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">黄景</div>
                  <div class="company">可复制领导力研究院</div>
                  <div class="position">院长</div>
                </div>
              </li>
              <li class="team_item">
                <img
                  src="../assets/images/service/teachers/LiangQingxiang.png"
                  alt=""
                />
                <div class="teacher_info">
                  <div class="name">梁庆祥</div>
                  <div class="company">兴远咨询</div>
                  <div class="position">首席导师</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="more_btn">
          <i class="icon el-icon-arrow-down"></i>
        </div>
      </div>
      <!-- 学员企业 -->
      <div class="students_company">
        <div class="company_box">
          <div class="contenter">
            <div class="title_box">
              <h2 class="title_name">学员企业</h2>
              <span class="line"></span>
            </div>
            <div class="company">
              <!-- <div class="title">一期学员企业</div> -->
              <img src="../assets/images/service/ZQstudent_zong.png" alt="" />
            </div>
            <!-- <div class="company" style="margin-top: 60px">
              <div class="title">二期学员企业</div>
              <img src="../assets/images/service/ZQstudent_02.png" alt="" />
            </div>
            <div class="company" style="margin-top: 60px">
              <div class="title">三期学员企业</div>
              <img src="../assets/images/service/ZQstudent_03.png" alt="" />
            </div> -->
          </div>
        </div>
        <div class="more_btn">
          <i class="icon el-icon-arrow-down"></i>
        </div>
      </div>
      <!-- 正奇成果 -->
      <div class="achievements">
        <div class="contenter">
          <div class="title_box">
            <h2 class="title_name">正奇成果</h2>
            <span class="line"></span>
          </div>
          <div class="show_box">
            <div class="title">一期成果展示</div>

            <div class="ach_banner">
              <el-carousel
                :interval="5000"
                type="card"
                height="23rem"
                indicator-position="none"
              >
                <el-carousel-item>
                  <div class="img_box">
                    <img
                      src="../assets/images/service/s_banner_01.png"
                      alt=""
                    />
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="img_box">
                    <img
                      src="../assets/images/service/s_banner_02.png"
                      alt=""
                    />
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="img_box">
                    <img
                      src="../assets/images/service/s_banner_03.png"
                      alt=""
                    />
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="img_box">
                    <img
                      src="../assets/images/service/s_banner_04.png"
                      alt=""
                    />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="achieve_list_box">
              <ul class="achieve_list">
                <li class="achieve_item">
                  中国网络安全产业联盟（CCIA）评选成长之星中占三
                </li>
                <li class="achieve_item">BCS安全创客汇十强中占二</li>
                <li class="achieve_item">
                  获得投资：
                  <div class="img_icon">
                    <img src="../assets/images/service/ZQresult.png" alt="" />
                  </div>
                </li>
              </ul>
              <ul class="achieve_list">
                <li class="achieve_item">
                  中国网络安全产业联盟（CCIA）评选潜力之星中占十一
                </li>
                <li class="achieve_item">ISC创新大赛十强中占三</li>
                <li class="achieve_item">
                  TECHSPARK星星之火大赛第一名-金睛云华
                </li>
              </ul>
            </div>
          </div>
          <div class="show_box">
            <div class="title">二期成果展示</div>

            <div class="ach_banner">
              <el-carousel
                :interval="5000"
                type="card"
                height="23rem"
                indicator-position="none"
              >
                <el-carousel-item>
                  <div class="img_box">
                    <img
                      src="../assets/images/service/s_banner_05.png"
                      alt=""
                    />
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="img_box">
                    <img
                      src="../assets/images/service/s_banner_06.png"
                      alt=""
                    />
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="img_box">
                    <img
                      src="../assets/images/service/s_banner_07.png"
                      alt=""
                    />
                  </div>
                </el-carousel-item>
                <el-carousel-item>
                  <div class="img_box">
                    <img
                      src="../assets/images/service/s_banner_08.png"
                      alt=""
                    />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="achieve_list_box">
              <ul class="achieve_list">
                <li class="achieve_item">
                  2021年中国网络安全产业（CCIA）评选成长之星中占一
                </li>
                <li class="achieve_item">ISC创新大赛十强中占二</li>
                <li class="achieve_item">
                  获得投资：
                  <div class="img_icon">
                    <img
                      src="../assets/images/service/ZQresult_02.png"
                      alt=""
                    />
                  </div>
                </li>
              </ul>
              <ul class="achieve_list">
                <li class="achieve_item">
                  2021年中国网络安全产业（CCIA）评选潜力之星中占十二
                </li>
                <li class="achieve_item">ISC创新大赛十强中占三</li>
                <li class="achieve_item">BCS安全创客汇二十强占五</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
export default {
  components: { Header, Footer },
  name: "service",
  data() {
    return {
      active: "2",
    };
  },
  methods: {},
  mounted() {},
  updated() {
    window.scroll(0, 0);
  },
};
</script>
<style lang="less" scoped>
@import url("../assets/css/service.less");
</style>